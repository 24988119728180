import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin( ScrollTrigger );

/* ------------------------------------------------
MODULE LOADER:
We only want to load the required to render each
individal page without a lot of overhead.

"Module loader" detects what modules are rendered
a page and loads only the scripts required to ensue
the modules function properly.
------------------------------------------------*/
const modules = document.querySelectorAll('[data-module]');

(async () => {
    for(let i = 0, length = modules.length; i < length; i++){
        let moduleName = modules[i].getAttribute('data-module');

        console.log('[WEB-APP] Loading module: /modules/' + moduleName + '.js');

        if (moduleName !== 0) {
            try{
                await import('./modules/'+moduleName+'.js');
            }
            catch(err){
                console.error(err)
            }
        }
    }

    /* ------------------------------------------------
    If an instance of APEX 4X (carousel) is loaded
    alongside GSAP modules. The trigger points of a GSAP
    module that occurs after the APEX module is thrown off.
    Perhaps there is a better way to trigger the GSAP
    ScrollTrigger refresh, but for now, we're setting
    a 500ms delay.
    ------------------------------------------------*/
    if ($A) {
        $A.on("load", function() {
            window.setTimeout(function(){
                console.log('[GSAP SCROLLTRIGGER REFRESH]');
                ScrollTrigger.refresh();
            }, 500);
        });
    };

})();

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}